import {Backdrop, BackdropProps, CircularProgress} from "@mui/material";
import theme from "../theme";
import React from "react";

export const PaperBackdrop: React.FC<BackdropProps> = ({open, children}) => {
    return (
        <div style={{"position": "relative"}}>
            <Backdrop sx={{color: "#FFFFFF", zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute", backgroundColor: "white", borderRadius: `${theme.shape.borderRadius * 5}px ! important`}} open={open}>
                {open && <CircularProgress color="warning"/>}
            </Backdrop>
            <div style={{padding: "30px", minHeight: "50px", maxHeight: open ? "80vh" : "initial", overflow: "hidden"}}>
                {children}
            </div>
        </div>
    );
}

