import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import theme from "../theme";

export interface SamaggiPaperProps {
    vertical?: boolean,
    mobile?: boolean,
    wide?: boolean,
    noPad?: boolean
}

const SamaggiPaper = styled(Paper, {
    shouldForwardProp: (prop) => !["vertical", "mobile", "wide", "noPad"].includes(prop as string)
}) <SamaggiPaperProps> (({vertical, mobile, wide = false, noPad = false}) => ({
    padding: noPad? "0" : "30px",
    borderRadius: `${theme.shape.borderRadius * 5}px ! important`,
    marginTop: mobile ? "30px" : "50px",
    marginLeft: vertical ? "2vw" : "10px",
    marginRight: vertical ? "2vw" : "10px",
    marginBottom: mobile ? "30px" : "50px",
    width: wide? "600px" : "425px",
    maxWidth: "86vw",
    boxSizing: "border-box"
}))

export default SamaggiPaper

