import {SSOBasicProps} from "../Main";
import React, {useEffect, useState} from "react";
import {checkToken} from "../support";
import {useNavigate, useSearchParams} from "react-router-dom";
import {LooseObject} from "./Permissions";

export const ProcessRequest: React.FC<SSOBasicProps> = (props) => {
    const navigate = useNavigate();
    const [firstLoad, setFirstLoad] = useState(true)
    const [params, ] = useSearchParams();

    function process() {
        const requestID = params.get("req")

        if (requestID === undefined || requestID === "" || requestID === null) {
            alert("Invalid Request");
            navigate("/sign-in");
        } else {
            localStorage.setItem("request-id", requestID);

            const paramData: LooseObject = {}

            params.forEach((value, key) => {
                if (key !== "req") {
                    paramData[key] = value
                }
            })

            localStorage.setItem("param-data", JSON.stringify(paramData));
        }

        checkToken(navigate).then((valid) => {
            props.setShowCover(false)
            if (valid) {
                navigate("/connect")
            } else {
                navigate("/sign-in?cont=connect")
            }
        })
    }


    useEffect(() => {
        if (firstLoad) {
            props.setShowCover(true);
            setFirstLoad(false);
            process();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <></>
    )
}