import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import theme from "../theme";
import logo from "../assets/auth_logo.png";
import { Grid, Toolbar } from "@mui/material";
import NavbarButton from "./NavbarButton";
import {useLocation, useNavigate} from "react-router-dom";
import SamaggiPaper from "./SamaggiPaper";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/system";
import {signOut} from "../support";

const NavbarText = styled(Typography) ({
    color: "#ffffff",
    textAlign: "center",
    verticalAlign: "center"
})

const NavbarOptions: React.FC<{ isLoading: boolean, isLoggedIn?: boolean, name?: string }> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    if (props.isLoading) {
        return (
            <Grid item>
                <NavbarText>
                    Loading...
                </NavbarText>
            </Grid>
        )
    } else if (!props.isLoggedIn) {
        return (
            <Grid item>
                <NavbarButton onClick={() => {navigate("/sign-in"); console.log("YAY")}}>
                    Sign In
                </NavbarButton>
            </Grid>
        )
    } else if (props.isLoggedIn) {
        return (
            <>
                <Grid item>
                    <NavbarText>
                        {props.name}
                    </NavbarText>
                </Grid>
                {location.pathname !== "/authenticate" && <Grid item height={"100%"}>
                    <NavbarButton onClick={() => {signOut(); navigate(location.pathname === "/connect" || location.pathname === "/authenticate" ? "/sign-in?cont=connect" : "/sign-in")}}>
                        Sign Out
                    </NavbarButton>
                </Grid>}
            </>
        )
    } else {
        return (
            <SamaggiPaper></SamaggiPaper>
        )
    }
}

const Logo = styled("img") <{vertical?: boolean, mobile?: boolean}> (({vertical, mobile}) => ({
    height: mobile ? "30px" : "50px",
    paddingTop: vertical ? "20px" : "30px",
    paddingBottom: vertical ? "0" : "30px"
}))

export const SamaggiNavbar: React.FC<{ isLoading: boolean, isLoggedIn?: boolean, name?: string, mobile?: boolean, vertical?: boolean }> = (props) => {
    // const location = useLocation();

    return (
        <ThemeProvider theme={theme}>
            <AppBar
                color="transparent"
                position="static"
                elevation={0}
                sx={{
                    color: "#FF6831",
                    minWidth: props.mobile? "200px" : "400px",
                    background: `linear-gradient(98.2deg, ${'#FF7F29'} 0%, ${'#FF4539'} 100%)`,
                }}
            >
                <Toolbar>
                    {!props.vertical && (
                        <>
                            <Logo src={logo} mobile={props.mobile} alt="Samaggi Samagom SSO Logo"/>
                            <Grid container justifyContent="right">
                                <Grid item>
                                    <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"}>
                                        <NavbarOptions isLoading={props.isLoading} isLoggedIn={props.isLoggedIn}
                                                       name={props.name}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                        )
                    }
                    {props.vertical && (
                        <Grid container direction={"column"} alignItems={"center"}>
                            <Grid item>
                                <Logo src={logo} mobile vertical alt="Samaggi Samagom SSO Logo"/>
                            </Grid>
                            <Grid item>
                                <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"}>
                                    <NavbarOptions isLoading={props.isLoading} isLoggedIn={props.isLoggedIn} name={props.name}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                </Toolbar>
            </AppBar>
        </ThemeProvider>
    )
}