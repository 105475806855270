import React, {useEffect, useState} from "react";
import {SSOBasicProps} from "../Main";
import {Grid, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import logo from "../assets/logo.png";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SamaggiField} from "../Components/SamaggiFields";
import {SamaggiButton} from "../Components/SamaggiButton";
import axios from "axios";
import {checkToken, handleError, handleSuccessToken} from "../support";

export const SignIn: React.FC<SSOBasicProps> = (props) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [params, ] = useSearchParams();

    function signIn() {
        props.setShowCover(true);
        axios.post("https://k3rkw7qg78.execute-api.eu-west-2.amazonaws.com/Prod/internal/sign-in", {"email": username, "password": password}).then(res => {
            console.log(res)

            props.setShowCover(false);

            handleSuccessToken(res)
            if (res.data.data.requiresVerification) {
                if (params.get("cont") === "connect") {
                    navigate("/verify?cont=connect")
                } else {
                    navigate("/verify")
                }
            } else {
                if (params.get("cont") === "connect") {
                    navigate("/connect")
                } else {
                    navigate("/")
                }
            }
        }).catch(error => {
            handleError(error, navigate, true);

            setPassword("");

            if (error.response !== undefined && error.response.status === 403) {
                alert("Incorrect Email or Password")
            }

            props.setShowCover(false)
        })
    }

    function keyDown(e: any) {
        if (e.key === 'Enter' && password !== "") {
            signIn()
        }
    }

    useEffect(() =>{
        checkToken(navigate, "").then((res) => {
            if (res === true) {
                navigate("/");
            }
        })
    })

    return (
        <>
            <Grid container direction={"column"} spacing={3}>
                <Grid item container direction={"column"} alignItems={"center"} spacing={2}>
                    <Grid item container direction={"row"} alignContent={"center"}>
                        <img src={logo} style={{maxWidth: "40%", margin: "auto"}} alt={"Samaggi Samagom Logo"}/>
                    </Grid>
                    <Grid item container direction={"column"} alignItems={"center"}>
                        <Grid item>
                            <Typography sx={{fontWeight: "600", fontSize: "20px", color: `#FF7F29`}}>Sign In</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{fontWeight: "500", color: "#333333"}}>with your Samaggi Account</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <SamaggiField label={"Email address"} id={"email"} autoComplete={"username"} value={username} fullWidth onKeyDown={keyDown} onChange={(e) => {setUsername(e.target.value)}}/>
                </Grid>
                <Grid item>
                    <SamaggiField label={"Password"} value={password} id="current-password" autoComplete={"current-password"} type={"password"} fullWidth onKeyDown={keyDown} onChange={(e) => {setPassword(e.target.value)}}/>
                </Grid>
                <Grid item>
                    <Link style={{fontSize: 13}} href={"#"} onClick={() => {navigate("/request-reset")}}>Forgot your password?</Link>
                </Grid>
                <Grid item container direction={"column"} spacing={1.5}>
                    <Grid item>
                        <SamaggiButton fullWidth onClick={() => {signIn()}}>Sign in</SamaggiButton>
                    </Grid>
                    <Grid item>
                        <SamaggiButton fullWidth colour={"#8c8c8c"} hoverColour={"#464646"} onClick={() => {
                            if (params.get("cont") === "connect") {
                                navigate("/register?cont=connect")
                            } else {
                                navigate("/register")
                            }
                        }}>Create an account</SamaggiButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}