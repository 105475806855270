import {SSOBasicProps} from "../Main";
import React, {useEffect} from "react";
import {Grid} from "@mui/material";
import {SamaggiTypography} from "../Components/SamaggiTypography";
import {isSignedIn} from "../support";
import {useNavigate} from "react-router-dom";
import logo from "../assets/logo.png";

export const Home: React.FC<SSOBasicProps> = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isSignedIn()) {
            navigate("/sign-in");
        }
    })

    return (
        <>
            <Grid container direction={"column"} spacing={"4"}>
                <Grid item container direction={"row"} alignContent={"center"}>
                    <img src={logo} style={{maxWidth: "40%", margin: "auto"}} alt={"Samaggi Samagom Logo"}/>
                </Grid>
                <Grid item container direction={"column"} spacing={"4"}>
                    <Grid item>
                        <SamaggiTypography large bold center>You're Signed In</SamaggiTypography>
                    </Grid>
                    <Grid item>
                        <SamaggiTypography center>You are successfully signed in to your Samaggi Account. You can use this service to authenticate logins from other sources.</SamaggiTypography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}