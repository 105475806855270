import React, {useState} from "react";
import {SSOBasicProps} from "../Main";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {Grid} from "@mui/material";
import {SamaggiTypography} from "../Components/SamaggiTypography";
import {SamaggiField} from "../Components/SamaggiFields";
import {SamaggiButton} from "../Components/SamaggiButton";

export const PasswordSet: React.FC<SSOBasicProps> = (props) => {
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const navigate = useNavigate();

    function submit() {
        if (!password.match(/[a-z]+/)) {
            alert("Your password must contain at least one lower-cased character.");
            return
        }

        if (password.length < 8) {
            alert("Your password must be at least 8 letter long.");
            return
        }

        if (!password.match(/[A-Z]+/)) {
            alert("Your password must contain at least one upper-cased character.");
            return
        }

        if (!password.match(/[0-9]+/)) {
            alert("Your password must contain at least one number.")
            return
        }

        props.setShowCover(true);

        const token = localStorage.getItem("token");

        axios.post("https://k3rkw7qg78.execute-api.eu-west-2.amazonaws.com/Prod/internal/change-password", {
            "token": token,
            "password": password
        }).then((res) => {
            props.setShowCover(false);

            alert(res.data.data.message);
            navigate("/sign-in");
        }).catch((error) => {
            props.setShowCover(false);

            alert("Failed to reset password. Please try again later.");
            console.warn(error);
        })
    }

    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid item container direction={"column"} spacing={1}>
                <Grid item>
                    <SamaggiTypography bold large center>Change Password</SamaggiTypography>
                </Grid>
                <Grid item>
                    <SamaggiTypography small center>Please enter your new password below. You will be logged out of all your previous sessions when you reset your password. You must complete this within 5 minutes.</SamaggiTypography>
                </Grid>
            </Grid>
            <Grid item container direction={"row"} spacing={2}>
                <Grid item md={6} xs={12}>
                    <SamaggiField label={"Password"} type={"password"} autoComplete={"new-password"} value={password} onChange={(e) => {setPassword(e.target.value)}} error={password2 !== password} required></SamaggiField>
                </Grid>
                <Grid item md={6} xs={12}>
                    <SamaggiField label={"Confirm Password"} type={"password"} autoComplete={"new-password"} value={password2} onChange={(e) => {setPassword2(e.target.value)}} error={password2 !== password} required></SamaggiField>
                </Grid>
            </Grid>
            <Grid item>
                <SamaggiButton fullWidth onClick={submit}>Change Password</SamaggiButton>
            </Grid>
        </Grid>
    )
}