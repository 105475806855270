export const courseAreas = [
    "Accounting/Finance",
    "Art and Design",
    "Business Administration/Management",
    "Computing and Information Technology",
    "Economics",
    "Education",
    "Engineering",
    "Humanities",
    "Law",
    "Music and Media",
    "Natural Sciences",
    "Political Sciences",
    "Social Sciences",
    "Not Applicable"
]
