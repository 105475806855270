import {SSOBasicProps} from "../Main";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {handleError, signOut} from "../support";
import {useNavigate} from "react-router-dom";
import {Checkbox, Grid, Link, Typography} from "@mui/material";
import {SamaggiButton} from "../Components/SamaggiButton";
import Paper from "@mui/material/Paper";
import {Colours, SamaggiTypography} from "../Components/SamaggiTypography";

export interface LooseObject {
    [key: string]: any
}

export const Permissions: React.FC<SSOBasicProps> = (props) => {
    const [firstLoad, setFirstLoad] = useState(true);
    const [showPermissions, setShowPermission] = useState(false);
    const [permissionsData, setPermissionsData] = useState([]);
    const [serviceName, setServiceName] = useState("");
    const [serviceImageURL, setServiceImageURL] = useState("");
    const [failureURL, setFailureURL] = useState("");
    const [verified, setVerified] = useState(false);

    const [grantsMap, setGrantsMap] = useState<LooseObject>({});

    const navigate = useNavigate();

    function appendCallbackParams(currentURL: string) {
        const url = new URL(currentURL);
        const callParamsString = localStorage.getItem("param-data")

        if (callParamsString !== null) {
            const callParams = JSON.parse(callParamsString);
            Object.keys(callParams).forEach((key) => {
                url.searchParams.append(key, callParams[key])
            })
        }

        return url.href;
    }

    function confirm() {
        const requestID = localStorage.getItem("request-id");
        const token = localStorage.getItem("internal-token");

        if (requestID === null) {
            alert("Invalid Request");
            navigate("/sign-in");
        }

        if (token === null) {
            alert("Invalid Token");
            navigate("/sign-in");
        }

        props.setShowCover(true);

        let grants: string[] = [];
        Object.keys(grantsMap).forEach((key) => {
            if (grantsMap[key as keyof typeof grantsMap] === true) {
                grants.push(key);
            }
        })

        axios.post("https://k3rkw7qg78.execute-api.eu-west-2.amazonaws.com/Prod/internal-sso/link", {"requestID": requestID, "token": token, "grants": grants}).then(res => {
            console.log(res)

            window.location.href = appendCallbackParams(res.data.data.callback);

            props.setShowCover(false);
        }).catch(error => {
            handleError(error, navigate);

            props.setShowCover(false)
        })
    }

    function cont() {
        props.setShowCover(true);
        const requestID = localStorage.getItem("request-id");
        const token = localStorage.getItem("internal-token");

        if (requestID === null) {
            alert("Invalid Request");
            navigate("/sign-in");
        }

        if (token === null) {
            alert("Invalid Token");
            navigate("/sign-in?cont=connect");
        }

        axios.post("https://k3rkw7qg78.execute-api.eu-west-2.amazonaws.com/Prod/internal-sso/is-linked", {"requestID": requestID, "token": token, "issueToken": true}).then(res => {
            props.setShowCover(false);

            window.location.href = appendCallbackParams(res.data.data.callback);
        }).catch(error => {
            handleError(error, navigate);

            props.setShowCover(false)
        })
    }

    function load() {
        const requestID = localStorage.getItem("request-id");
        const token = localStorage.getItem("internal-token");

        if (requestID === null) {
            alert("Invalid Request");
            navigate("/sign-in");
        }

        if (token === null) {
            alert("Invalid Token");
            navigate("/sign-in?cont=connect");
        }

        axios.post("https://k3rkw7qg78.execute-api.eu-west-2.amazonaws.com/Prod/internal-sso/permission-service-info", {"requestID": requestID, "token": token}).then(res => {
            console.log(res)

            props.setShowCover(false);

            setShowPermission(res.data.data.showPermissions);
            setServiceName(res.data.data.serviceName);
            setServiceImageURL(res.data.data.serviceLogo);
            setPermissionsData(res.data.data.requestedPermissions);
            setFailureURL(res.data.data.failURL);
            setVerified(res.data.data.serviceVerified);

            let initData: LooseObject = {};
            res.data.data.requestedPermissions.forEach((perm: any) => {
                initData[perm["dataID"]] = perm["default"];
            })

            console.log(initData);
            setGrantsMap(initData);
        }).catch(error => {
            handleError(error, navigate, true);

            navigate("/sign-in?cont=connect")

            props.setShowCover(false)
        })
    }

    function handleChange(e: any) {
        let data = grantsMap;
        grantsMap[e.target.id as keyof typeof grantsMap] = e.target.checked;
        setGrantsMap(data);
        console.log(grantsMap);
    }

    useEffect(() => {
        if (firstLoad) {
            props.setShowCover(true);
            setFirstLoad(false);
            load();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (showPermissions) {
        return (
            <>
                <Grid container direction={"column"} spacing={4}>
                    <Grid item container spacing={2}>
                        <Grid item container alignContent={"center"}>
                            <img src={serviceImageURL} style={{maxWidth: "30%", margin: "auto", borderRadius: "50%"}} alt={`Logo for ${serviceName}`}/>
                        </Grid>
                        <Grid item container direction={"column"} alignItems={"center"}>
                            <Grid item>
                                <SamaggiTypography center style={{color: verified? "#009900" : "#ad2b2b"}} bold>{verified ? "VERIFIED" : "NOT VERIFIED"}</SamaggiTypography>
                            </Grid>
                        </Grid>
                        <Grid item container direction={"column"} alignItems={"center"} spacing={0.2}>
                            <Grid item>
                                <SamaggiTypography center large><SamaggiTypography center bold large inline>{serviceName}</SamaggiTypography> wants to access your Samaggi Account</SamaggiTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction={"column"} spacing={0.5} alignItems={"center"}>
                        <Grid item>
                            <SamaggiTypography center>Signed in as <SamaggiTypography bold inline colour={Colours.samaggiBlue}>{localStorage.getItem("display-name")}</SamaggiTypography></SamaggiTypography>
                        </Grid>
                        <Grid item>
                            <Link component="button" onClick={() => {signOut(); navigate("/sign-in?cont=connect")}}>Not You?</Link>
                        </Grid>
                    </Grid>
                    <Grid item container direction={"column"} alignItems={"center"} spacing={4}>
                        <Grid item>
                            <SamaggiTypography><SamaggiTypography bold inline>{serviceName}</SamaggiTypography> is requesting access to:</SamaggiTypography>
                        </Grid>
                        <Grid item container direction={"row"} alignItems={"center"} spacing={1}>
                            {permissionsData.map((permission) => (
                                <Grid item xs={12}>
                                    <Paper style={{width: "full", padding: "20px", borderRadius: "12px", background: "#FBFBFB"}}>
                                        <Grid container direction={"row"}>
                                            <Grid item container direction={"column"} xs={8} alignItems={"left"}>
                                                {permission["optionType"] === "REQUIRED" && (<Grid item>
                                                    <SamaggiTypography bold colour={Colours.samaggiOrange}>Required</SamaggiTypography>
                                                </Grid>)}
                                                {["OPTIONAL_DEF", "OPTIONAL"].includes(permission["optionType"]) && (<Grid item>
                                                    <SamaggiTypography bold colour={Colours.samaggiBlue}>Optional</SamaggiTypography>
                                                </Grid>)}
                                                {permission["optionType"] === "REMOVE" && (<Grid item>
                                                    <SamaggiTypography bold colour={Colours.gray}>Removed</SamaggiTypography>
                                                </Grid>)}
                                                <Grid item>
                                                    <SamaggiTypography bold xsmall italic inline colour={Colours.samaggiBlue}>
                                                        <SamaggiTypography bold colour={permission["optionType"] === "REMOVE" ? Colours.darkGray : Colours.black} strikethrough={permission["optionType"] === "REMOVE"}>
                                                            {permission["name"]}
                                                        </SamaggiTypography>
                                                        {" " + (permission["status"] as string).toUpperCase()}
                                                    </SamaggiTypography>
                                                </Grid>
                                                <Grid item>
                                                    <SamaggiTypography small colour={permission["optionType"] === "REMOVE" ? Colours.darkGray : Colours.black}>
                                                        {permission["description"]}
                                                    </SamaggiTypography>
                                                </Grid>
                                            </Grid>
                                            <Grid item container direction={"column"} xs={4} justifyContent={"center"} alignContent={"end"}>
                                                {permission["optionType"] !== "REMOVED" && (<Grid item sx={{alignSelf: "end"}}>
                                                    <Checkbox onChange={handleChange} id={permission["dataID"]} key={permission["dataID"]} defaultChecked={permission["default"]} disabled={permission["optionType"] === "REQUIRED" || permission["optionType"] === "REMOVE"}/>
                                                </Grid>)}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item container direction={"row"} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <SamaggiButton fullWidth colour={"#8c8c8c"} hoverColour={"#464646"} onClick={() => {window.location.href = failureURL}}>Cancel</SamaggiButton>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SamaggiButton fullWidth onClick={() => {confirm()}}>Continue</SamaggiButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    } else {
        return (
            <>
                <Grid container direction={"column"} spacing={4}>
                    <Grid item container alignContent={"center"}>
                        <img src={serviceImageURL} style={{maxWidth: "30%", margin: "auto", borderRadius: "50%"}} alt={`Logo for ${serviceName}`}/>
                    </Grid>
                    <Grid item container direction={"column"} alignItems={"center"} spacing={1.5}>
                        <Grid item>
                            <Typography sx={{fontWeight: "600", fontSize: 24, textAlign: "center"}}>Continue to {serviceName}?</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{textAlign: "center"}}>You have previously authenticated with {serviceName}. You can now continue to the service.</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction={"row"} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <SamaggiButton fullWidth colour={"#8c8c8c"} hoverColour={"#464646"} onClick={() => {window.location.href = failureURL}}>Cancel</SamaggiButton>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SamaggiButton fullWidth onClick={() => {cont()}}>Continue</SamaggiButton>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }

}