import React, {useState} from "react";
import {SSOBasicProps} from "../Main";
import {Grid, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import {SamaggiDate, SamaggiField, SamaggiSelect} from "../Components/SamaggiFields";
import {countries} from "../Data/countries";
import {SamaggiFormContainer, SamaggiFormSection} from "../Components/SamaggiForm";
import {courseAreas} from "../Data/courseAreas";
import {phoneMap} from "../Data/phoneMap";
import {years} from "../Data/years";
import {SamaggiButton} from "../Components/SamaggiButton";
import axios from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import {handleError, handleSuccessToken} from "../support";

export const Register: React.FC<SSOBasicProps> = (props) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [nickname, setNickname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [birth, setBirth] = useState(new Date());
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [thResident, setThResident] = useState("");
    const [educationStatus, setEducationStatus] = useState("");

    const [countryCode, setCountryCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [institution, setInstitution] = useState("");
    const [courseArea, setCourseArea] = useState("");
    const [courseName, setCourseName] = useState("");
    const [startYear, setStartYear] = useState("");
    const [endYear, setEndYear] = useState("");

    const navigate = useNavigate();
    const [params, ] = useSearchParams();

    function submit() {
        function f(x: any) {
            return x === ""
        }

        interface LooseObject {
            [key: string]: any
        }

        console.log((new Date(birth)).getMonth())

        if ([firstName, lastName, email, password, password2, birth, country, educationStatus, nickname].some(f) || (country !== "THA" && thResident === "")) {
            alert("Please fill all required fields");
            return
        }

        if (password !== password2) {
            alert("Please ensure your password was entered correctly.")
        }

        const birthDate = new Date(birth);

        let data: LooseObject = {
            "email": email,
            "password": password,
            "given": firstName,
            "family": lastName,
            "nickname": nickname,
            "birthdate": `${birthDate.getFullYear()}-${birthDate.getMonth() + 1}-${birthDate.getDate()}`,
            "residenceCountry": country,
            "residenceCity": city,
            "currentlyStudying": educationStatus === "Currently Studying",
            "previousThailandResident": thResident === "Yes",
        }

        if (![countryCode, phoneNumber].some(f)) {
            data["phone"] = {
                "country": countryCode,
                "number": phoneNumber
            }
        }

        if (![institution, courseName, courseArea, startYear, endYear].some(f)) {
            data["latestUniversity"] = {
                "name": institution,
                "active": educationStatus === "Currently Studying",
                "course": courseName,
                "courseArea": courseArea,
                "yearBegin": startYear,
                "yearComplete": endYear
            }
        }

        if (password.length < 8) {
            alert("Your password must be at least 8 letter long.");
            return
        }

        if (!password.match(/[a-z]+/)) {
            alert("Your password must contain at least one lower-cased character.");
            return
        }

        if (!password.match(/[A-Z]+/)) {
            alert("Your password must contain at least one upper-cased character.");
            return
        }

        if (!password.match(/[0-9]+/)) {
            alert("Your password must contain at least one number.")
            return
        }

        props.setShowCover(true)

        axios.post("https://k3rkw7qg78.execute-api.eu-west-2.amazonaws.com/Prod/internal/register", data).then(res => {
            console.log(res)
            handleSuccessToken(res);
            props.setShowCover(false);

            if (params.get("cont") === "connect") {
                navigate("/verify?cont=connect")
            } else {
                navigate("/verify")
            }
        }).catch(error => {
            handleError(error, navigate);

            props.setShowCover(false)
        })
    }

    function alpha3(country: string) {
        return countries[country as keyof typeof countries];
    }

    return (
        <Grid container direction={"column"} spacing={5}>
            <Grid item container direction={"column"} alignItems={"center"}>
                <Grid item>
                    <Typography sx={{fontWeight: "600", fontSize: "30px", color: `#FF7F29`}}>Register</Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{fontWeight: "500", fontSize: "20px", color: "#333333"}}>A New Samaggi Account</Typography>
                </Grid>
            </Grid>
            <SamaggiFormContainer item>
                <SamaggiFormSection label={"Personal & Account Information"} direction={"column"} spacing={2}>
                    <Grid item container direction={"row"} spacing={2}>
                        <Grid item md={6} xs={12}>
                            <SamaggiField label={"First Name"} value={firstName} onChange={(e) => setFirstName(e.target.value)} required></SamaggiField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <SamaggiField label={"Last Name"} value={lastName} onChange={(e) => {setLastName(e.target.value)}} required></SamaggiField>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <SamaggiField label={"Nickname/Preferred Name"} value={nickname} onChange={(e) => {setNickname(e.target.value)}} required></SamaggiField>
                    </Grid>
                    <Grid item xs={12}>
                        <SamaggiField label={"Email Address"} type={"email"} value={email} onChange={(e) => {setEmail(e.target.value)}} required></SamaggiField>
                    </Grid>
                    <Grid item container direction={"row"} spacing={2}>
                        <Grid item md={6} xs={12}>
                            <SamaggiField label={"Password"} type={"password"} value={password} onChange={(e) => {setPassword(e.target.value)}} error={password2 !== password} required></SamaggiField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <SamaggiField label={"Confirm Password"} type={"password"} value={password2} onChange={(e) => {setPassword2(e.target.value)}} error={password2 !== password} required></SamaggiField>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <SamaggiDate label={"Date of Birth"} value={birth} onChange={(e) => setBirth((e === null) ? new Date() : e)} required></SamaggiDate>
                    </Grid>
                    <Grid item container direction={"row"} spacing={2}>
                        <Grid item md={6} xs={12}>
                            <SamaggiSelect label={"Country of Residence"} value={country} onChange={(e) => {setCountry(e.target.value as string)}} required>
                                {Object.keys(countries).map((k) => (
                                    <MenuItem value={alpha3(k)}>{k}</MenuItem>
                                ))}
                            </SamaggiSelect>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <SamaggiField label={"City of Residence"} value={city} onChange={(e) => {setCity(e.target.value)}} required></SamaggiField>
                        </Grid>
                    </Grid>
                    <Grid item container direction={"row"} spacing={2} alignItems={"flex-end"}>
                        {country !== "THA" && <Grid item xs={12} md={6}>
                            <SamaggiSelect value={thResident} label={"Previously Resident or National of Thailand"} onChange={(e) => {setThResident(e.target.value as string)}} required>
                                {["Yes", "No"].map((fieldName) => (
                                    <MenuItem value={fieldName}>{fieldName}</MenuItem>
                                ))}
                            </SamaggiSelect>
                        </Grid>}
                        <Grid item xs={12} md={country === "THA" ? 12 : 6} >
                            <SamaggiSelect value={educationStatus} label={"Current Education Status"} onChange={(e) => {setEducationStatus(e.target.value as string)}} required>
                                {["Currently Studying", "Not Studying"].map((fieldName) => (
                                    <MenuItem value={fieldName}>{fieldName}</MenuItem>
                                ))}
                            </SamaggiSelect>
                        </Grid>
                    </Grid>

                </SamaggiFormSection>
                <SamaggiFormSection label={"Contact Information (Optional)"}>
                    <Grid item container direction={"row"} spacing={2}>
                        <Grid item xs={3}>
                            <SamaggiSelect value={countryCode} label={"Country Code"} onChange={(e) => {setCountryCode(e.target.value as string)}} renderValue={(selected) => {return "+" + selected}}>
                                {Object.keys(phoneMap).map((fieldName) => (
                                    <MenuItem value={phoneMap[fieldName as keyof typeof phoneMap]}>{fieldName}</MenuItem>
                                ))}
                            </SamaggiSelect>
                        </Grid>
                        <Grid item xs={9}>
                            <SamaggiField label={"Phone Number"} value={phoneNumber} onChange={(e) => {setPhoneNumber(e.target.value)}}></SamaggiField>
                        </Grid>
                    </Grid>
                </SamaggiFormSection>
                <SamaggiFormSection label={"Latest Education (Optional)"} direction={"column"}>
                    <Grid item>
                        <SamaggiField label={"Institution Name"} value={institution} onChange={(e) => {setInstitution(e.target.value)}}></SamaggiField>
                    </Grid>
                    <Grid item>
                        <SamaggiSelect label={"Course Area"} value={courseArea} onChange={(e) => {setCourseArea(e.target.value as string)}}>
                            {courseAreas.map((area) => (
                                <MenuItem value={area}>
                                    {area}
                                </MenuItem>
                            ))}
                        </SamaggiSelect>
                    </Grid>
                    <Grid item>
                        <SamaggiField label={"Course Name"} value={courseName} onChange={(e) => {setCourseName(e.target.value)}}></SamaggiField>
                    </Grid>
                    <Grid item container direction={"row"} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <SamaggiSelect label={"Start Year"} value={startYear} onChange={(e) => {setStartYear(e.target.value as string)}}>
                                {years.map((year) => (
                                    <MenuItem value={year}>{year}</MenuItem>
                                ))}
                            </SamaggiSelect>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SamaggiSelect label={"End Year"} value={endYear} onChange={(e) => {setEndYear(e.target.value as string)}}>
                                {years.map((year) => (
                                    <MenuItem value={year}>{year}</MenuItem>
                                ))}
                            </SamaggiSelect>
                        </Grid>
                    </Grid>
                </SamaggiFormSection>
            </SamaggiFormContainer>
            <Grid item>
                <SamaggiButton type={"submit"} onClick={() => {submit()}} fullWidth>Register</SamaggiButton>
            </Grid>
        </Grid>
    )
}