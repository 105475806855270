import React from "react";
import {Grid} from "@mui/material";
import {GridProps} from "@mui/material";
import Typography from "@mui/material/Typography";

export const SamaggiFormSection: React.FC<{label?: string} & GridProps> = (props) => {
    return (
        <Grid item container direction={"column"} spacing={1.2}>
            <Grid item>
                <Typography sx={{fontWeight: "600", fontSize: "22px"}}>{props.label}</Typography>
            </Grid>
            <Grid item container {...props} spacing={2}>
                {props.children}
            </Grid>
        </Grid>
    )
}

export const SamaggiFormContainer: React.FC<GridProps> = (props) => {
    return (
        <Grid item={props.item} container direction={"column"} spacing={4}>
            {props.children}
        </Grid>
    )
}
