import React, {useState} from "react";
import {SSOBasicProps} from "../Main";
import {Grid} from "@mui/material";
import {SamaggiTypography} from "../Components/SamaggiTypography";
import {SamaggiField} from "../Components/SamaggiFields";
import {SamaggiButton} from "../Components/SamaggiButton";
import axios from "axios";
import {useNavigate} from "react-router-dom";

export const RequestReset: React.FC<SSOBasicProps> = (props) => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    function submit() {
        props.setShowCover(true);

        axios.post("https://k3rkw7qg78.execute-api.eu-west-2.amazonaws.com/Prod/internal/forget-password", {
            "email": email
        }).then((res) => {
            props.setShowCover(false);

            alert(res.data.data.message);
            navigate("/sign-in");
        }).catch((error) => {
            props.setShowCover(false);

            alert("Failed to send password reset email. Please try again later.");
            console.warn(error);
        })
    }

    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid item container direction={"column"} spacing={1}>
                <Grid item>
                    <SamaggiTypography bold large center>Reset Password</SamaggiTypography>
                </Grid>
                <Grid item>
                    <SamaggiTypography center small>Please enter your email address below. If an account exists, a password reset instruction will be sent to the email address.</SamaggiTypography>
                </Grid>
            </Grid>
            <Grid item>
                <SamaggiField label={"Email Address"} value={email} onChange={(e) => {setEmail(e.target.value)}} type={"email"}/>
            </Grid>
            <Grid item>
                <SamaggiButton fullWidth onClick={submit}>Request Password Reset</SamaggiButton>
            </Grid>
        </Grid>
    )
}