import { styled } from "@mui/system";
import Button, {ButtonProps} from "@mui/material/Button";
import theme from "../theme";
import React, {FC} from "react";
import Typography from "@mui/material/Typography";

const UnStyledNavbarButton: FC<ButtonProps> = ({ children, ...rest }) => (
    <Button size="large" fullWidth disableRipple disableFocusRipple {...rest}>
        <Typography sx={{fontWeight: "600"}}>{children}</Typography>
    </Button>
)


const NavbarButton= styled(UnStyledNavbarButton) ({
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
        textDecoration: "underline",
        backgroundColor: "transparent",
        textDecorationColor: "#ffffff",
        textUnderlineOffset: theme.spacing(1),
    }
});
export default NavbarButton;
