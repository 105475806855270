import axios from "axios";

export function handleError(error: any, navigate: any, stay: boolean = false) {
    console.warn(error)

    if (error.response !== undefined && error.response.status === 403) {
        localStorage.removeItem("internal-token");
        localStorage.removeItem("internal-token-restricted");
        localStorage.removeItem("internal-token-expiry");
        localStorage.removeItem("internal-token-requires-verification");
        localStorage.removeItem("display-name");

        window.dispatchEvent(new Event("sign-in-state"));

        if (!stay) {
            navigate("/sign-in");
        }
    } else {
        try {
            if (error.response !== undefined) {
                alert(error.response.data.data.reason);
                console.warn(error.response.data);
            } else {
                alert(error.code);
            }
        } catch (e) {
            alert(error.code);
        }
    }
}

export function signOut() {
    localStorage.removeItem("internal-token");
    localStorage.removeItem("internal-token-restricted");
    localStorage.removeItem("internal-token-expiry");
    localStorage.removeItem("internal-token-requires-verification");
    localStorage.removeItem("display-name");

    window.dispatchEvent(new Event("sign-in-state"));
}

export function handleSuccessToken(res: any) {
    localStorage.setItem("internal-token", res.data.data.token);
    localStorage.setItem("internal-token-restricted", res.data.data.restricted);
    localStorage.setItem("internal-token-expiry", res.data.data.expiry);
    localStorage.setItem("internal-token-requires-verification", res.data.data.requiresVerification);
    localStorage.setItem("display-name", res.data.data.displayName);

    window.dispatchEvent(new Event("sign-in-state"));
}

export function isSignedIn() {
    return (localStorage.getItem("internal-token") !== null)
}

export function checkToken(navigator: any, to: string = "") {
    return new Promise((resolve, ) => {
        if (!isSignedIn()) {
            if (to !== "") {
                navigator(to);
            }
            resolve(false);
            return
        }

        const token = localStorage.getItem("internal-token");
        const expiryString = localStorage.getItem("internal-token-expiry");

        let expiry = 0

        if (expiryString !== null) {
            expiry = parseFloat(expiryString);
        }

        if (Date.now() / 1000 > expiry) {
            if (to !== "") {
                navigator(to);
            }
            signOut();
            resolve(false);
            return
        }

        axios.post("https://k3rkw7qg78.execute-api.eu-west-2.amazonaws.com/Prod/internal/check-token", {"token": token}).then(res => {
            console.log(res);
            try {
                if (res.data.data.valid === true) {
                    localStorage.setItem("internal-token-restricted", res.data.data.restricted);
                    localStorage.setItem("internal-token-expiry", res.data.data.expiry);
                    localStorage.setItem("internal-token-requires-verification", res.data.data.requiresVerification);
                    resolve(true);
                } else {
                    if (to !== "") {
                        navigator(to);
                    }
                    resolve(false);
                }
            } catch (e) {
                console.warn(e);
                resolve(false);
            }
        }).catch((error) => {
            console.warn(error)
            resolve(false);
            try {
                if (error.response !== undefined) {
                    alert(error.response.data.data.reason);
                } else {
                    alert(error.code);
                }
            } catch (e) {
                alert(error.code);
                console.warn(e);
            }
            if (to !== "") {
                navigator(to);
            }
        })
    })
}
