import React, {useState} from "react";
import {Grid, MenuItem, Select, SelectProps, TextField, TextFieldProps} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DatePicker, DatePickerProps} from "@mui/x-date-pickers";

export const SamaggiField: React.FC<{ label: string } & TextFieldProps> = (props) => {
    const {label: _, error: __, ...rest} = props;

    return (
        <Grid container direction="column" spacing={0.5}>
            <Grid item>
                <Typography sx={{fontWeight: "600"}}>{props.label}</Typography>
            </Grid>
            <Grid item>
                <TextField size={"small"} fullWidth placeholder={props.label} onChange={props.onChange} error={props.error || (props.required === true && props.value === "")} {...rest}/>
            </Grid>
        </Grid>
    )
}

export const SamaggiDate: React.FC<{ label: string, required?: boolean, error?: boolean } & DatePickerProps<Date>> = (props) => {
    const [changed, setChanged] = useState(false);

    function handler(e: any, c: any) {
        if (props.onChange !== undefined) {
            props.onChange(e, c)
        }
        setChanged(true);
    }

    return (
        <Grid container direction="column" spacing={0.5}>
            <Grid item>
                <Typography sx={{fontWeight: "600"}}>{props.label}</Typography>
            </Grid>
            <Grid item>
                <DatePicker label={""} onChange={handler} format="DD/MM/YYYY" slotProps={{
                    textField: {
                        fullWidth: true,
                        size: "small",
                        error: props.error || (props.required === true && !changed)
                    }
                }}/>
            </Grid>
        </Grid>
    )
}

export const SamaggiSelect: React.FC<{ label: string } & SelectProps> = (props) => {
    const {label: _, ...rest} = props;

    return (
        <Grid container direction="column" spacing={0.5}>
            <Grid item>
                <Typography sx={{fontWeight: "600"}}>{props.label}</Typography>
            </Grid>
            <Grid item>
                <Select onChange={props.onChange} placeholder={"Select an Option"} size={"small"} error={props.error || (props.required === true && props.value === "")} fullWidth {...rest}>
                    <MenuItem disabled value={""}>Select An Option</MenuItem>
                    {props.children}
                </Select>
            </Grid>
        </Grid>
    )
}
