import {styled} from "@mui/system";
import {Typography} from "@mui/material";

export interface SamaggiTypographyProps {
    bold?: boolean
    center?: boolean
    colour?: Colours,
    large?: boolean,
    inline?: boolean,
    small?: boolean,
    strikethrough?: boolean
    xsmall?: boolean
    italic?: boolean
}

export enum Size {
    normal = "inherit",
    large = "24px"
}

export enum Colours {
    black = "#000000",
    samaggiOrange = "#ff9031",
    samaggiBlue = "#293c7e",
    gray = "#9f9f9f",
    darkGray = "#646464"
}

export const SamaggiTypography = styled(Typography, {
    shouldForwardProp: (prop) => !["bold", "center", "large", "inline", "strikethrough", "small", "xsmall", "italic", "colour"].includes(prop as string)
}) <SamaggiTypographyProps> (({bold = false, center = false, large = false, inline = false, strikethrough = false, small = false, xsmall = false, italic = false, colour = Colours.black}) => ({
    textAlign: center ? "center" : "left",
    fontWeight: bold ? "600": "400",
    color: colour,
    fontSize: large ? "24px": (small ? "16px" : (xsmall ? "14px" : "19px")),
    fontStyle: italic ? "italic": "normal",
    display: inline ? "inline": "inherit",
    textDecorationLine: strikethrough? "line-through": "none"
}))