import React, {useEffect} from "react";
import {SSOBasicProps} from "../Main";
import axios from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";

export const ProcessReset: React.FC<SSOBasicProps> = (props) => {
    const [params, ] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        process(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function process() {
        if (!params.has("data")) {
            alert("Invalid Password Reset Link");
            navigate("/sign-in");
            return
        }

        props.setShowCover(true);

        const data = params.get("data");

        axios.post("https://k3rkw7qg78.execute-api.eu-west-2.amazonaws.com/Prod/internal/reset-password-otp", {
            "data": data
        }).then((res) => {
            props.setShowCover(false);

            // alert(res.data.data.message);
            localStorage.setItem("token", res.data.data.token);
            navigate("/change-password");
        }).catch((error) => {
            props.setShowCover(false);

            if (error.response !== undefined) {
                const err = error.response.data.data.reason;
                alert(err);
            } else {
                alert("Failed to verify password reset link.");
            }

            navigate("/sign-in")
            console.warn(error);
        })
    }

    return (
        <></>
    )
}