import React, {PropsWithChildren, useEffect, useState} from "react";
import {SamaggiNavbar} from "./Components/SamaggiNavbar";
import {Grid, useMediaQuery} from "@mui/material";
import theme from "./theme";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {SignIn} from "./Pages/SignIn";
import SamaggiPaper from "./Components/SamaggiPaper";
import {Register} from "./Pages/Register";
import {Verify} from "./Pages/Verify";
import {PaperBackdrop} from "./Components/SamaggiBackdrop";
import {Permissions} from "./Pages/Permissions";
import {ProcessRequest} from "./Pages/ProcessRequest";
import {Home} from "./Pages/Home";
import {checkToken} from "./support";
import {RequestReset} from "./Pages/RequestReset";
import {ProcessReset} from "./Pages/ProcessReset";
import {PasswordSet} from "./Pages/PasswordSet";

export interface SSOBasicProps extends PropsWithChildren {
    mobile: boolean,
    vertical: boolean,
    setShowCover: React.Dispatch<React.SetStateAction<boolean>>
}

export interface SSOReadOnlyProps extends SSOBasicProps {
    loggedIn: boolean
    userID: string
    name: string
    sessionID: string
}

export interface SSOWriteProps extends SSOReadOnlyProps {
    setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
    setUserID: React.Dispatch<React.SetStateAction<string>>
    setName: React.Dispatch<React.SetStateAction<string>>
}

export interface SSOFullProps extends SSOWriteProps {
    setSessionID: React.Dispatch<React.SetStateAction<string>>
}

export interface SSOToken {
    token: string
    setToken: React.Dispatch<React.SetStateAction<string>>
}

export const Main: React.FC< PropsWithChildren > = () => {
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const vertical = useMediaQuery(theme.breakpoints.down("sm"));
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;

    const widePaths = [
        "/register",
        "/connect"
    ]

    const [showCover, setShowCover] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [name, setName] = useState("");

    function updateBar() {
        const displayName = localStorage.getItem("display-name");
        const token = localStorage.getItem("internal-token");
        if (displayName !== null && token !== null) {
            setLoggedIn(true);
            setName(displayName)
        } else {
            setLoggedIn(false);
            setName("")
        }
    }

    window.addEventListener("sign-in-state", () => {
        setTimeout(() => {
            updateBar()
        }, 500)
    })

    useEffect(() => {
        updateBar()
    })

    useEffect(() => {
        console.log('Location changed');
        if (!["/sign-in", "/register", "/authenticate", "/request-reset", "/change-password", "/reset-password"].includes(location.pathname)) {
            checkToken(navigate, "/sign-in")
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);


    return (
        <>
            <SamaggiNavbar isLoading={false} name={name} isLoggedIn={loggedIn} mobile={mobile} vertical={vertical}/>
            <Grid container direction={"column"} alignItems={"center"}>
                <SamaggiPaper mobile={mobile} vertical={vertical} wide={widePaths.includes(currentPath)} noPad>
                    <PaperBackdrop open={showCover}>
                        <Routes>
                            <Route path="/sign-in" element={<SignIn mobile={mobile} vertical={vertical} setShowCover={setShowCover}/>} />
                            <Route path="/" element={<Home mobile={mobile} vertical={vertical} setShowCover={setShowCover}/>} />
                            <Route path="/register" element={<Register mobile={mobile} vertical={vertical} setShowCover={setShowCover}/>} />
                            <Route path="/verify" element={<Verify mobile={mobile} vertical={vertical} setShowCover={setShowCover}/>} />
                            <Route path="/connect" element={<Permissions mobile={mobile} vertical={vertical} setShowCover={setShowCover}/>} />
                            <Route path="/authenticate" element={<ProcessRequest mobile={mobile} vertical={vertical} setShowCover={setShowCover}/>} />
                            <Route path="/request-reset" element={<RequestReset mobile={mobile} vertical={vertical} setShowCover={setShowCover}/>} />
                            <Route path="/reset-password" element={<ProcessReset mobile={mobile} vertical={vertical} setShowCover={setShowCover}/>} />
                            <Route path="/change-password" element={<PasswordSet mobile={mobile} vertical={vertical} setShowCover={setShowCover}/>} />
                        </Routes>
                    </PaperBackdrop>
                </SamaggiPaper>
            </Grid>
        </>
    )
}
