import {SSOBasicProps} from "../Main";
import React, {useEffect, useState} from "react";
import {SamaggiField} from "../Components/SamaggiFields";
import {Grid, Typography} from "@mui/material";
import {SamaggiButton} from "../Components/SamaggiButton";
import axios from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import {handleError} from "../support";

export const Verify: React.FC<SSOBasicProps> = (props) => {
    const [OTP, setOTP] = useState("");
    const navigate = useNavigate();
    const [params, ] = useSearchParams();

    function resend() {
        props.setShowCover(true);

        const token = localStorage.getItem("internal-token");

        axios.post("https://k3rkw7qg78.execute-api.eu-west-2.amazonaws.com/Prod/internal/resend-activation", {"token": token}).then(res => {
            console.log(res)

            props.setShowCover(false);
        }).catch(error => {
            handleError(error, navigate);

            props.setShowCover(false)
        })
    }

    function submit() {
        props.setShowCover(true);

        const token = localStorage.getItem("internal-token");

        axios.post("https://k3rkw7qg78.execute-api.eu-west-2.amazonaws.com/Prod/internal/verify-email", {"token": token, "data": OTP}).then(res => {
            console.log(res)

            if (res.data.data.success) {
                if (params.get("cont") === "connect") {
                    navigate("/connect")
                } else {
                    navigate("/")
                }
            }

            props.setShowCover(false);
        }).catch(error => {
            handleError(error, navigate);

            props.setShowCover(false)
        })
    }

    useEffect(() => {
        console.log(localStorage.getItem("internal-token"))

        setTimeout(() => {
            if (localStorage.getItem("internal-token") === null) {
                alert("Session expired, please sign in again.");
                navigate(-1);
            }
        }, 100)
    })

    return (
        <>
            <Grid container direction={"column"} spacing={3}>
                <Grid item container direction={"column"} spacing={1} alignItems={"center"}>
                    <Grid item>
                        <Typography sx={{fontWeight: "600", fontSize: 24}}>Verify Email</Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{textAlign: "center"}}>An email was sent to your email address with a verification code. Please enter it below to verify your email address.</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <SamaggiField label={"OTP Code"} value={OTP} onChange={(e) => {setOTP(e.target.value)}} required/>
                </Grid>
                <Grid item container direction={"column"} spacing={1.5}>
                    <Grid item>
                        <SamaggiButton fullWidth onClick={resend} colour={"#8c8c8c"} hoverColour={"#464646"} >Resend Email</SamaggiButton>
                    </Grid>
                    <Grid item>
                        <SamaggiButton fullWidth onClick={submit}>Verify</SamaggiButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
