import {styled} from "@mui/material";
import Button from "@mui/material/Button";

export const SamaggiButton = styled(Button)<{ colour?: string, hoverColour?: string }>(({colour = "#ff9031", hoverColour = "#a64704"
}) => ({
    backgroundColor: colour,
    color: "#FFFFFF",
    fontWeight: "600",
    borderRadius: "10px",
    height: "45px",
    fontSize: "17px",
    borderWidth: "1px",
    borderColor: "#222222",
    boxShadow: "0 1px 3px #9E9E9E",
    "&:hover": {
        backgroundColor: hoverColour
    }
}))